'use client';

import { useState } from 'react';

//* HOC's
import { withAccountContext } from '@/context';

//* Helpers
import formJson from '@/helpers/forms.json';

//* Components
import { Page, Form, Text, CustomLink, Button, Title } from '@/components/common';

//* Style
import FormStyle from '@/styles/pagesStyles/FormStyle';

const Login = ({ login, socialLogin }) => {
	//! State
	const [error, setError] = useState(null);

	const handleLogin = (values) => {
		login(values).catch((err) => {
			if (err.status == 401) {
				setError(err.data.message);
			}
		});
	};

	return (
		<Page className={'login'}>
			<FormStyle className='formContainer'>
				<div className='formLeft'>
					<Title
						tag={'h2'}
						className={'formTitle arm-regular'}
						text={'SignIn'}
					/>
				</div>

				<div className='formRight'>
					<div className='formOtherLinkWrap FlexBox'>
						<Text
							className={'h10 arm-regular greyMid'}
							text={'NewHere'}
						/>

						<CustomLink
							url={'/register'}
							className='h10  underline arm-regular white'
							content={'CreateAnAccount'}
						/>
					</div>

					{error && <Text className={'red pSm arm-regular'}>{error}</Text>}

					<Form
						wrapClass={'whiteForm'}
						submit={handleLogin}
						className={'formItems'}
						{...formJson.loginParams}
					/>

					<div className='ForgotPasswordWrap'>
						<CustomLink
							url={'/forgot-password'}
							className='underline h10 arm-bold greyLight'
							content={'ForgotPassword'}
						/>
					</div>

					<div className='loginOtherWey'>
						<Text
							className={'loginOtherWeyTitle h10 greyLight arm-regular'}
							text={'ContinueWith'}
						/>

						<div className='loginOtherWeyBtn FlexBox spaceBetween'>
							<Button
								onClick={() => socialLogin('google')}
								className={'btn btn-mid btn-white'}
								children={
									<div className='FlexBox alignMiddle'>
										<i className='icon-search-3' />
										<span>Google</span>
									</div>
								}
							/>

							<Button
								onClick={() => socialLogin('facebook')}
								className={'btn btn-mid btn-white'}
								children={
									<div className='FlexBox alignMiddle'>
										<i className='icon-Mask-Group-68' />
										<span>Facebook</span>
									</div>
								}
							/>
						</div>
					</div>
				</div>
			</FormStyle>
		</Page>
	);
};

export default withAccountContext(Login, ['login', 'socialLogin']);
